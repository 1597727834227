<template>
    <div class="text-center"  style="width: 92%; display: flex; flex-direction: column;  align-items: center; justify-content: center; margin: auto;">
<!--            <img alt="Logo inicio" src="../assets/img/logo.png" class="m-5" style="width: 400px;">-->
        <h1 class="mb-4">{{ $t('taxSituation') }}</h1>
        <table class="table table-striped table-bordered">
            <thead>
            <tr>
                <th style="width: 5%;"></th>
                <th class="text-left">{{ $t('name') }}</th>
                <th style="width: 15%;">{{ $t('size') }}</th>
                <th style="width: 30%;">{{ $t('lastUpdate') }}</th>
                <th style="width: 5%;">{{ $t('actions') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr style="background: #fafbfe" v-if="taxSituation.id > 0">
                <td>
                    <font-awesome-icon icon="file-pdf" style="font-size: 2em"></font-awesome-icon>
                </td>
                <td class="text-left">{{ taxSituation.name }}</td>
                <td>{{ getRealSize(taxSituation.size) }}</td>
                <td>{{ taxSituation.updated }}</td>
                <td>
                    <b-button type="button" variant="white" class="ml-2" @click="downloadTaxSituation"
                              v-b-tooltip.hover.bottom :title="$t('download')">
                        <font-awesome-icon icon="download"></font-awesome-icon>
                    </b-button>
                </td>
            </tr>
            <tr style="background: #fafbfe" v-if="taxSituation.id <= 0">
                <td colspan="5">{{ $t('noRecord') }}</td>
            </tr>
            </tbody>
        </table>
        
        <h1 class="mb-4 mt-4">{{ $t('notices') }}</h1>
        <template v-if="notices.length > 0">
            <b-card
                border-variant="#e4e6e7"
                header-border-variant="#e4e6e7"
                align="left"
                class="mb-5"
                :header-html="'<b>'+notice.name+'</b>'"
                v-for="(notice, index) in notices"
                :key="index"
                style="width: 100%;"
            >
                <b-card-text style="overflow: hidden;" v-html="notice.content"></b-card-text>
            </b-card>
        </template>
        <template v-else>
            <b-card
                border-variant="secondary"
                align="center"
                class="mb-5"
                style="width: 100%"
            >
                <b-card-text>{{ $t('notNotices') }}</b-card-text>
            </b-card>
        </template>
    </div>
</template>

<script>
import { downloadFile, getNotices, getTaxSituation } from '@routes';
import { downloadAttachment } from '@/js/utilities';

export default {
    data() {
        return {
            breadcrumb: {
                title: this.$t('supplierPortal'),
                path: []
            },
            notices: [],
            taxSituation: {
                id: 0,
                encryptedName: '',
                name: '',
                extension: '',
                size: '',
                updated: ''
            }
        };
    },
    mounted() {
        let $this = this;
        
        $this.$emit('update-breadcrumb', $this.breadcrumb);
        
        this.axios.get(getNotices()).then(response => {
            if (response.data.code === 'OK') {
                $this.notices = response.data.notices;
                $this.$forceUpdate();
            }
        });
        
        this.axios.get(getTaxSituation()).then(response => {
            if (response.data.code === 'OK') {
                if (response.data.taxSituation.id > 0) {
                    $this.taxSituation = response.data.taxSituation;
                    $this.$forceUpdate();
                }
            }
        });
        
        this.$parent.updateUserData();
    },
    methods: {
        getRealSize(bytes) {
            const units = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
            
            let size = bytes;
            let unitIndex = 0;
            
            while (size > 1024 && unitIndex < units.length - 1) {
                size /= 1024.0;
                unitIndex += 1;
            }
            
            const sizeFormatted = size.toFixed(2);
            
            return `${sizeFormatted} ${units[unitIndex]}`;
        },
        downloadTaxSituation() {
            let fileUrl = downloadFile(this.taxSituation.encryptedName);
            let $this = this;
            
            this.axios.get(fileUrl, { responseType: 'blob' }).then(async response => {
                downloadAttachment(response, $this.taxSituation.name);
            });
        }
    }
};
</script>
